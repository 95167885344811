<template>
  <div>
    <a-modal v-model:visible="visible" title="关联症候群" @ok="handleOk" okText="确认" cancelText="取消" :afterClose="closeModal">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="名称">
          <syndromeSelector v-model="name" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive } from "vue";
import { AddRelation } from "/src/api/syndrome.js";
import syndromeSelector from '/src/components/selector/syndromeSelector.vue';
export default {
  components: {
    syndromeSelector,
  },
  data() {
    return {
      visible: this.modelValue,
      reload: false,
      visible2: false,
      medicineFilter: "",
      prescription: "",
      name: "",
      editableData: reactive({}),
      selectedRows: {},
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      editableData: reactive({}),
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {
  },
  methods: {
    filterNameInitial(input) {
      this.medicineFilter = input?.toLowerCase();
    },
    filter() {
      return true
    },
    async handleOk() {
      let _this = this;
      let parentId = this.$store.state.syndromeList.find(item => item.name == this.name).id
      if (this.record.id == parentId) {
        _this.$message.warning("不能关联自身", function () { });
        return
      }
      let res = await AddRelation({ id: this.record.id, parentId })
      if (res.errCode == 0) {
        _this.$message.success("关联成功", function () { });
        _this.visible = false
        _this.reload = true
        this.name = ""
      }

    },

    closeModal() {
      this.$emit('update:modelValue', false);
      if (this.reload) {
        this.$emit("reload")
      }
    },

  },
  watch: {
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
</style>
